<template>
  <div class="home">
    <div id="loader" v-show="loading">
      <i class="fa-solid fa-spinner fa-spin"></i>
    </div>
    <img src="../assets/imgs/logo-new.jpg" alt="" srcset="" class="logo">
    <swiper :modules="modules" :allowTouchMove="false" :speed="2000" :parallax="true" @swiper="onSwiper" class="mySwiper">
      <swiper-slide class="slide-one" :style="{ backgroundImage: 'url(' + bgImage + ')' }">
        <div class="left" v-show="!finishedTyping">
          <div id="container">
            <div id="butterfly">
              <div id="leftwing"></div>
              <div id="rightwing"></div>
            </div>
            
            <div id="futterbly">
              <div class="left wing"></div>
              <div class="right wing"></div>
            </div>
          </div>
        </div>
        <div class="inner slide-one-overlay">
          <h1 class="no-bg" id="firstPara" data-swiper-parallax="-1000">

          </h1>
          <h1 class="with-background no-bg" id="secondPara" data-swiper-parallax="-1200">
            
          </h1>
          <!-- <h1 class="no-bg" id="thirdPara" data-swiper-parallax="-1400">
            
          </h1> -->
          <a href="javascript:void(0);" class="large-btn enter-site" :class="[enterSiteClass]" @click="nextSlide">
            ENTER SITE
          </a>
        </div>
      </swiper-slide>
      <swiper-slide class="slide-two" data-swiper-parallax="-23%">
        <div class="inner">          
          <div class="inner-border">
            
            <div class="top-icons">
              <div class="left-icons">
                <img src="../assets/imgs/lighteningicon.png" alt="Eye In The Wild" data-swiper-parallax="-1000">
                <img src="../assets/imgs/moonicon1.png" alt="Eye In The Wild" data-swiper-parallax="-1100">
              </div>
              <div class="right-icons">
                <img src="../assets/imgs/batteryicon1.png" alt="Eye In The Wild" data-swiper-parallax="-1200">
              </div>
            </div>
            <div class="left">
              <h1 class="normal-title" data-swiper-parallax="-1000">
                It’s not what you see,<br> It’s how you see it.
              </h1>
              <p class="copy" data-swiper-parallax="-1500">
                We invite you to get behind the camera so we can address the crisis in front of it.
              </p>
              <p class="copy" data-swiper-parallax="-1700">
                Positive images that show solutions to the crisis i.e. irrigation and rainwater harvesting are highly encouraged.
              </p>
              <div class="cta">
                <a href="javascript:void(0)" class="small-btn yellow-btn" style="display:none;" data-swiper-parallax="-1800" @click="nextSlide">
                  UPLOAD
                </a>
                <a @click="redirectToGallery" href="javascript:void(0)" style="display:none;" class="small-btn" data-swiper-parallax="-1900">
                  GALLERY
                </a>
                <a href="javascript:void(0)" class="small-btn" data-swiper-parallax="-2000" @click="slideTo(4)">
                  JUDGES
                </a>
              </div>
              <div class="cta-last">
                <a href="https://api.whatsapp.com/send?phone=254716905417" target="_blank" class="small-btn yellow-btn" data-swiper-parallax="-1800">
                  WHATSAPP US <i class="fa-brands fa-whatsapp"></i>
                </a>
              </div>
            </div>
            <div class="bottom">
              <h3 class="small-title" data-swiper-parallax="-2200">
                Submissions Closed
              </h3>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="slide-three upload-slide" style="display:none;">
        <div class="inner">
          <div class="left">
            <div class="overlay">
              <div class="inner-copy">
                <div class="top-section">
                  <h3 data-swiper-parallax="-1000">
                    UPLOAD
                  </h3>
                </div>
                <div class="img-section" v-if="previewImage !== ''">
                  <img :src="previewImage" alt="" srcset="" data-swiper-parallax="-1200">
                </div>
                <div class="bottom-section">
                  <a href="javascript:void(0);" data-swiper-parallax="-1500" @click="slidePrev">
                    <i class="fa-solid fa-arrow-left" data-swiper-parallax="-1300"></i> <span data-swiper-parallax="-1300">BACK</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="overlay">
              <div class="inner-copy">
                <form @submit="onSubmitUploadForm">
                  <input type="text" v-model="form.full_names" name="name" placeholder="Enter your name*" class="input-type" data-swiper-parallax="-1000" required>
                  <input type="email" v-model="form.email" name="email" id="email" placeholder="Email*" class="input-type" data-swiper-parallax="-1100" required>
                  <input type="tel" v-model="form.phone_number" name="phone" id="phone" placeholder="Phone number*" class="input-type" data-swiper-parallax="-1200" required>
                  <select v-model="form.country" name="country" id="country" data-swiper-parallax="-1200" required>
                    <option value="">
                      Country
                    </option>
                    <option value="Kenya">
                      Kenya
                    </option>
                    <option value="South Africa">
                      South Africa
                    </option>
                    <option value="Tanzania">
                      Tanzania
                    </option>
                    <option value="Uganda">
                      Uganda
                    </option>
                    <option value="Ethiopia">
                      Ethiopia
                    </option>
                    <option value="Mozambique">
                      Mozambique
                    </option>
                    <option value="Namibia">
                      Namibia
                    </option>
                    <option value="Rwanda">
                      Rwanda
                    </option>
                  </select>
                  <input type="text" v-model="form.business_unit" name="business" placeholder="Business Unit*" class="input-type" data-swiper-parallax="-1000" required>
                  <fieldset>    
                    <label for="category" data-swiper-parallax="-1300">Select category</label>   
                    <div class="fieldset-inner" style="display:flex;" data-swiper-parallax="-1400">
                      <label>
                        <input type="radio" name="radio" value="Farming" v-model="form.category" />
                        <span>Farming</span>
                      </label>
                      <label>
                        <input type="radio" name="radio" value="Wildlife" v-model="form.category"/>
                        <span>Wildlife</span>
                      </label>
                      <label>
                        <input type="radio" name="radio" value="Nomadic Cultures" v-model="form.category"/>
                        <span>Nomadic Cultures</span>
                      </label>
                      <label>
                        <input type="radio" name="radio" value="Lifestyle" v-model="form.category"/>
                        <span>Lifestyle</span>
                      </label>
                      <label>
                        <input type="radio" name="radio" value="Activation and Engagement" v-model="form.category"/>
                        <span>Activation and Engagement</span>
                      </label>
                    </div>
                  </fieldset>
                  <select name="level" v-model="form.level" id="level" data-swiper-parallax="-1500" required>
                    <option value="">
                      Level
                    </option>
                    <option value="Amateur">
                      Amateur
                    </option>
                    <option value="Intermediate">
                      Intermediate
                    </option>
                    <option value="Pro">
                      Pro
                    </option>
                  </select>
                  <input type="file" name="image" id="image" class="input-file" data-swiper-parallax="-1600"  @change="uploadPhoto" style="margin-bottom: 0em;" ref="file" required>
                  <p style="font-size: 0.9em;margin-bottom: 1em;" data-swiper-parallax="-1650">View photo formating instructions <a :href="imgSpecsUrl" target="_blank">here</a></p>
                  <!-- <input type="text" v-model="form.high_res_url" name="name" placeholder="Hi Res Photo URL*" class="input-type" data-swiper-parallax="-1650" required> -->
                  <textarea v-model="form.caption" name="Image Caption" id="caption" rows="3" placeholder="Image Caption" data-swiper-parallax="-1700" required></textarea>
                  <div class="input-group" data-swiper-parallax="-1750">
                    <input type="checkbox" id="terms" name="terms" value="Agreed" required>
                    <label for="terms"> By submitting your information you agree to the <a :href="pdfUrl" target="_blank">terms and conditions</a></label>
                  </div>
                  <input type="submit" value="COMPLETE" class="input-submit small-btn yellow-btn" data-swiper-parallax="-1800">
                </form>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="slide-three image-slide" style="display:none;">
        <div class="inner">
          <div class="left">
            <div class="overlay">
              <div class="inner-copy">
                <div class="top-section">
                  <h3 data-swiper-parallax="-1000">
                    UPLOAD
                  </h3>
                </div>
                <div class="img-section" v-if="previewImage !== ''">
                  <img :src="previewImage" alt="" srcset="" data-swiper-parallax="-1100">
                </div>
                <div class="bottom-section">
                  <a href="javascript:void(0);" data-swiper-parallax="-1200" @click="slidePrev">
                    <i class="fa-solid fa-arrow-left"></i> BACK
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="overlay">
              <div class="inner-copy share-section">
                <h3 data-swiper-parallax="-1000">
                  UPLOADED<br>SUCCESSFULLY!
                </h3>
                <p data-swiper-parallax="-1200">
                  Share on social media <i class="fa-solid fa-share-nodes"></i>
                </p>
                <div class="share">
                  <a href="https://wa.me/?text=Join%20the%20Eye%20in%20the%20Wild%20photography%20competition%20and%20stop%20the%20drought,%20one%20click%20at%20a%20time%20https://safaleyeinthewild.safalgroup.com" target="_blank" data-swiper-parallax="-1300">
                    <i class="fa-brands fa-whatsapp"></i>
                  </a>
                  <a href="https://www.facebook.com/sharer/sharer.php?u=https://safaleyeinthewild.safalgroup.com/"  target="_blank" onclick="window.open(this.href, 'targetWindow', 'toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=250'); return false" data-swiper-parallax="-1400">
                    <i class="fa-brands fa-facebook-f"></i>
                  </a>
                  <a href="http://twitter.com/share?text=Join the Eye in the Wild photography competition and stop the drought, one click at a time&url=https://safaleyeinthewild.safalgroup.com/&hashtags=SAFAL,EYEINTHEWILD" target="_blank" data-swiper-parallax="-1500">
                    <i class="fa-brands fa-twitter"></i>
                  </a>
                  <a href="https://www.instagram.com/" target="_blank" data-swiper-parallax="-1600">
                    <i class="fa-brands fa-instagram"></i>
                  </a>
                </div>
                <div class="share top">
                  <a href="javascript:void(0)" class="small-btn yellow-btn" style="display:none;" data-swiper-parallax="-1800" @click="redirectToGallery">
                    GALLERY
                  </a>
                  <a href="javascript:void(0)" class="small-btn yellow-btn" data-swiper-parallax="-1900" @click="slideTo(4)">
                    JUDGES
                  </a>
                  <a href="https://api.whatsapp.com/send?phone=254716905417" class="small-btn yellow-btn" data-swiper-parallax="-1800">
                    WHATSAPP US
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="slide-judges">
        <div class="inner">
          <div class="top">
            <h4 class="large-title" data-swiper-parallax="-1000">
              JUDGES
            </h4>
            <p class="large-copy" data-swiper-parallax="-1200">
              <strong>
                Your photos will be judged by the best in the industry
              </strong>
            </p>
          </div>
          <div class="copy-wrap">
            <div class="copy">
              <div class="img-wrapper">
                <img src="../assets/imgs/eric.jpg" alt="Eye In The Wild" data-swiper-parallax="-1500">
                <h5 class="small-title" data-swiper-parallax="-1700">
                  ERIC MUSEMBI 
                </h5>
              </div>
              <div class="img-wrapper">
                <img src="../assets/imgs/duncan.jpg" alt="Eye In The Wild" data-swiper-parallax="-1700">
                <h5 class="small-title" data-swiper-parallax="-1900">
                  DUNCAN MUHANI
                </h5>
              </div>
              <div class="img-wrapper">
                <img src="../assets/imgs/sharon.jpg" alt="Eye In The Wild" data-swiper-parallax="-1300">
                <h5 class="small-title" data-swiper-parallax="-1500">
                  SHARON MALLYA
                </h5>
              </div>
              <div class="img-wrapper">
                <img src="../assets/imgs/victor.jpg" alt="Eye In The Wild" data-swiper-parallax="-1700">
                <h5 class="small-title" data-swiper-parallax="-1900">
                  VICTOR ONDIWO
                </h5>
              </div>
              <div class="img-wrapper">
                <img src="../assets/imgs/deseree.jpg" alt="Eye In The Wild" data-swiper-parallax="-1700">
                <h5 class="small-title" data-swiper-parallax="-1900">
                  DESEREÉ VAN JAARVELD
                </h5>
              </div>
              <div class="img-wrapper">
                <img src="../assets/imgs/scott.jpg" alt="Eye In The Wild" data-swiper-parallax="-1300">
                <h5 class="small-title" data-swiper-parallax="-1500">
                  SCOTT MAYNARD
                </h5>
              </div>
              <div class="img-wrapper">
                <img src="../assets/imgs/alice.jpg" alt="Eye In The Wild" data-swiper-parallax="-1500">
                <h5 class="small-title" data-swiper-parallax="-1700">
                  ALICE KAGACHA
                </h5>
              </div>
              <div class="img-wrapper">
                <img src="../assets/imgs/emmanuel.jpg" alt="Eye In The Wild" data-swiper-parallax="-1500">
                <h5 class="small-title" data-swiper-parallax="-1700">
                  EMMANUEL LUVUUMA
                </h5>
              </div>
            </div>
          </div>
          <div class="bottom back-section">
            <a href="javascript:void(0);" data-swiper-parallax="-1900" @click="slideTo(1)">
              <i class="fa-solid fa-arrow-left"></i> BACK
            </a>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
  // Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

// import required modules
import { Parallax } from "swiper";

import Typed from 'typed.js';


export default {
  name: 'HomeView',
  components: {
    Swiper,
    SwiperSlide,
    Typed
  },
  data() {
      return {
        //Test
        // baseURL:'https://safaleyeinthewild.tk/app',
        // galleryUrl: 'https://safaleyeinthewild.tk/#/gallery',
        //End Test
        // baseURL:'https://safaleyeinthewild.safalgroup.com/app',
        // galleryUrl: 'https://safaleyeinthewild.safalgroup.com/uat/#/gallery',
        //Staff
        // baseURL:'https://staff.safaleyeinthewild.safalgroup.com/app',    
        baseURL:'https://beanafrica.tk/app',        
        galleryUrl:'https://staff.safaleyeinthewild.safalgroup.com/#/gallery',
        //Local
        // baseURL:'http://eyeinthewild.test',
        // galleryUrl:'http://localhost:8080/#/gallery',
        shouldRetype: localStorage.getItem('retype'),
        swiper: null,
        finishedTyping: false,
        enterSiteClass:'',
        uploadedImageUrl:'',
        pdfUrl: '../assets/docs/terms-and-conditions.pdf',
        imgSpecsUrl: '../assets/docs/img-specs.pdf',
        bgImage:require('../assets/imgs/slider2.jpg'),
        // previewImage:require('../assets/imgs/upload-image.jpg'),
        previewImage:'',
        firstTyped:'',
        secondTyped:'',
        thirdTyped:'',
        loading: false,
        full_names:"",
        form:{
          full_names:"",
          email:"",
          phone_number:"",
          category:"",
          level:"",
          country:"",
          high_res_url:"https://www.safalgroup.com/",
          business_unit:"",
          photo:"",
          caption:""
        }
      }
  },
  methods: {
     redirectToGallery(){
        window.location.replace(this.galleryUrl);
     },
     async onSubmitUploadForm(e){
      e.preventDefault();

      //Show Loader
      this.showLoadingAlert();

      let x = this;

      const formData = new FormData();
      formData.append('full_names',x.form.full_names);
      formData.append('email',x.form.email);
      formData.append('phone_number',x.form.phone_number);
      formData.append('category',x.form.category);
      formData.append('level',x.form.level);
      formData.append('country',x.form.country);
      formData.append('high_res_url',x.form.high_res_url);
      formData.append('business_unit',x.form.business_unit);
      formData.append('photo',x.form.photo);
      formData.append('caption',x.form.caption);

      const res = await fetch(`${this.baseURL}/api/v1/participant-signup`,{
        method: 'POST',
        headers:{
          'Accept':'application/json',
          // 'Content-type':'multipart/form-data'
        },
        body:formData
      });

      const data = await res.json();

      if(data.success){
        //Hide Spinner
        this.hideLoadingAlert();
        //Show Success alert
        this.showSuccessAlert('<p>Uploaded image successfuly</p>');
        //Update aploaded image url
        this.uploadedImageUrl = `${this.baseURL}${data.data.attributes.media_url}`;
        //Clear Form
        this.clearForm();
        //Slide to share slide
        this.nextSlide();
      }else{
        //Hide Spinner
        this.hideLoadingAlert();
        //Show Error alert
        var errors = '';

        data.errors.forEach((err) => {
          errors = errors.concat(" ", `<li style="margin-bottom:0.5em;text-align: left;"><strong>${err.title}</strong> : ${err.details}</li>`);
        });

        this.showErrorAlert(errors);
      }
    },
    clearForm(){
      this.form.full_names = '';
      this.form.email = '';
      this.form.phone_number = '';
      this.form.category = '';
      this.form.level = '';
      this.form.country = '';
      this.form.photo = '';
      this.form.caption = '';
      // this.form.high_res_url = '';
      this.form.business_unit = '';
    },
    uploadPhoto(e){
      this.form.photo = this.$refs.file.files[0];

      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e =>{
          this.previewImage = e.target.result;
      };
    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    nextSlide(){
      this.swiper.slideNext();
    },
    slidePrev(){
      this.swiper.slidePrev();
    },
    slideTo(i){
      this.swiper.slideTo(i);
    },
    showSlideOneElements(){
      var firstPara = document.querySelector('#firstPara');
      var secondPara = document.querySelector('#secondPara'); 
      var overlay = document.querySelector('.slide-one-overlay');

      firstPara.innerHTML = 'Drought in';
      secondPara.innerHTML = `<span class="firstLetter">F</span><span class="neon"><img src="${require('../assets/imgs/camera.png')}" alt=""><span class="neon-span delay1"></span></span>CUS`;

      //Show button
      this.enterSiteClass = 'is-show';
      //Change Background Image
      this.bgImage = require('../assets/imgs/slider1.jpg');
      //Remove Overlay
      overlay.classList.remove('slide-one-overlay');
      //Hide butterflies
      this.finishedTyping = true;
    },
    initTypedFirst(){
      var firstPara = document.querySelector('#firstPara');
      this.firstTyped = new Typed(firstPara, {
        strings: [
          'Drought in'
        ],
        showCursor: false,
        typeSpeed: 40,
        onComplete: (self) => {
          this.typedSecond();
        },
      });
    },
    typedSecond(){
      var secondPara = document.querySelector('#secondPara'); 
      var overlay = document.querySelector('.slide-one-overlay');
      this.secondTyped = new Typed(secondPara, {
        contentType: 'html',
        strings: [
          `<span class="firstLetter">F</span><span class="neon"><img src="${require('../assets/imgs/camera.png')}" alt=""><span class="neon-span delay1"></span></span>CUS`
        ],
        showCursor: false,
        startDelay: 50,
        typeSpeed: 130,
        backSpeed: 10,
        onComplete: (self) => {
          //Show button
          this.enterSiteClass = 'is-show';
          //Change Background Image
          this.bgImage = require('../assets/imgs/slider1.jpg');
          //Remove Overlay
          overlay.classList.remove('slide-one-overlay');
          //Hide butterflies
          this.finishedTyping = true;
        },
      });
    },
    typedThird(){
      var thirdPara = document.querySelector('#thirdPara'); 
      var overlay = document.querySelector('.slide-one-overlay');
      this.thirdTyped = new Typed(thirdPara, {
        strings: [
          'IS REEL'
        ],
        showCursor: true,
        startDelay: 50,
        typeSpeed: 150,
        backSpeed: 10,
        onComplete: (self) => {
          //Show button
          this.enterSiteClass = 'is-show';
          //Change Background Image
          this.bgImage = require('../assets/imgs/slider1.jpg');
          //Remove Overlay
          overlay.classList.remove('slide-one-overlay');
          //Hide butterflies
          this.finishedTyping = true;
        }
      });
    },
    showSuccessAlert(text) {
      // Use sweetalert2
      this.$swal({
        icon: "success",
        html: text,
        closeOnEsc: false,
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
    },
    showLoadingAlert(){
      this.loading = true;
    },
    hideLoadingAlert(){
      this.loading = false;
    },
    showErrorAlert(text) {
      // Use sweetalert2
      this.$swal({
        icon: 'error',
        title: 'Oops...errors occured!',
        html: `<ul style"text-align: left;">${text}</ul>`,
        allowOutsideClick: false,
      });
    },
  },
  setup() {
    return {
      modules: [Parallax],
    };
  },
  mounted(){
    if (!this.shouldRetype) {      
      this.initTypedFirst();
      localStorage.setItem( 'retype', true );
    }else{
      this.showSlideOneElements();
    }
  }
}
</script>

<style lang="scss">
  @import "https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;800&display=swap";
  @import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css";
  @import "../assets/scss/common.scss";
  @import "../assets/scss/upload.scss";
</style>
