<template>
    <div class="gallery-page">
        <div id="loader" v-show="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
        </div>
        <div id="voter-registration-form" v-if="this.token === null || this.token === ''">
            <div class="overlay">
              <div class="inner-copy">
                <h2>
                    Sign In
                </h2>
                <form @submit="onSubmitSignInForm">
                  <input type="email" v-model="signInForm.email" name="email" id="loginemail" placeholder="Email*" class="input-type" required>
                  <input type="password" v-model="signInForm.password" name="password" id="loginpassword" placeholder="Enter password*" class="input-type" required>
                  <div class="input-group-btns">
                    <input type="submit" value="SIGN IN" class="input-submit small-btn yellow-btn">
                  </div>
                </form>
              </div>
            </div>
        </div>
        <div class="inner">
            <div class="top">
                <h4 class="large-title">
                    Dashboard
                </h4>
                <a href="javascript:void(0);" class="small-btn yellow-btn" @click="redirectToHome">
                    <i class="fa-solid fa-arrow-left" style="margin-right: 0.5em;"></i> BACK
                </a>
            </div>
            <div class="copy" v-if="image !== null">
                <div class="img-wrapper" :style="{ backgroundImage: `url(${uploadsUrl + image.attributes.media_name})` }">
                    <a href="javascript:void(0);" data-fancybox="gallery" :data-src="uploadsUrl + image.attributes.media_name" :data-caption="image.attributes.caption">

                    </a>
                    <div class="vote">
                        <div class="right">
                            <h3 @click="votePhoto(image)">
                                {{ image.attributes.votes }} VOTE(S)
                                <i class="fa-solid fa-heart" :style="[ image.attributes.voted ? 'color:rgb(252 204 104);' : 'color: #fff;']"></i>
                            </h3>
                            <p>Share On:</p>
                            <ul class="share-social">
                                <li>
                                    <a class="whatsapp" :href="'https://wa.me/?text=Vote%20for%20me%20in%20the%20Safal%20Eye%20in%20the%20Wild%20photography%20competition%20and%20stop%20the%20drought,%20one%20click%20at%20a%20time.%20Kindly%20vote%20here.' + homeUrl + 'photo/?img=' + image.id" target="_blank">
                                        <i class="fa-brands fa-whatsapp"></i>
                                    </a>
                                </li>
                                <li>
                                    <a class="facebook" :href="'https://www.facebook.com/sharer/sharer.php?u=' + homeUrl + 'photo/?img=' + image.id"  target="_blank" onclick="window.open(this.href, 'targetWindow', 'toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=250'); return false">
                                        <i class="fa-brands fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a class="twitter" :href="'http://twitter.com/share?text=Vote for me in the Eye in the Wild photography competition and stop the drought, one click at a time&url='+ homeUrl + 'photo/?img=' + image.id" target="_blank">
                                        <i class="fa-brands fa-twitter"></i>
                                    </a>
                                </li>
                                <!-- <li>
                                    <a class="copy-link" href="#">
                                        <i class="fa-solid fa-link"></i>
                                    </a>
                                </li> -->
                            </ul>
                            <div class="copy-to-clipboard">
                                <input 
                                v-on:focus="$event.target.select()" 
                                ref="clone" 
                                readonly 
                                :value="homeUrl + 'photo/?img=' + image.id"/>
                                <button @click="copy">
                                    <i class="fa-solid fa-copy"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <VueEternalLoading :load="load"></VueEternalLoading>
            <div class="back-section">
                <a href="javascript:void(0);" @click="redirectToHome">
                    <i class="fa-solid fa-arrow-left"></i> BACK
                </a>
                <div class="img-wrapper">
                    <img src="../assets/imgs/logo-new.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//Fancybox
import { Fancybox } from "@fancyapps/ui";

//Lazy load
import lozad from 'lozad';

import { defineComponent, ref, getCurrentInstance } from 'vue';
import { VueEternalLoading, LoadAction } from '@ts-pro/vue-eternal-loading';

// const URL = 'https://safaleyeinthewild.tk/app/api/v1/user-photo';
// const URL = 'https://safaleyeinthewild.safalgroup.com/app/api/v1/user-photo';
// const URL = 'https://staff.safaleyeinthewild.safalgroup.com/app/api/v1/user-photo';
const URL = 'https://beanafrica.tk/app/api/v1/user-photo';
// const URL = 'http://eyeinthewild.test/api/v1/user-photo';
const PAGE_SIZE = 6;

export default {
    name: 'ParticipantDashboardView',
    components: {
        Fancybox
    },
    data() {
        return {
            // homeUrl:'https://safaleyeinthewild.tk/',
            // baseURL:'https://safaleyeinthewild.tk/app',

            // localBaseURL: 'http://eyeinthewild.test',
            // homeUrl:'http://localhost:8080/#/',
            // baseURL:'http://eyeinthewild.test',
            // uploadsUrl:'http://eyeinthewild.test/storage/photos/2022/',

            // homeUrl:'https://safaleyeinthewild.safalgroup.com/uat/#/',
            // baseURL:'https://safaleyeinthewild.safalgroup.com/app',            
            // uploadsUrl:'https://safaleyeinthewild.safalgroup.com/competitionapp/storage/app/public/photos/2023/',

            //Staff
            homeUrl:'https://staff.safaleyeinthewild.safalgroup.com/#/',
            // baseURL:'https://staff.safaleyeinthewild.safalgroup.com/app',            
            // uploadsUrl:'https://staff.safaleyeinthewild.safalgroup.com/competitionapp/storage/app/public/photos/2023/',
            baseURL:'https://beanafrica.tk/app',          
            uploadsUrl:'https://beanafrica.tk/competitionapp/storage/app/public/photos/2023/',

            loading: false,
            showRegForm: false,
            showLogInForm: false,
            image:null,
            currentPage: 1,
            pageCount: 0,
            token:JSON.parse( localStorage.getItem('token') ),
            user_id:JSON.parse( localStorage.getItem('user_id') ),
            form:{
                full_names:"",
                email:"",
                phone_number:"",
                password:"",
                password_confirmation:"",
            },
            signInForm: {
                email:"",
                password:"",
            }
        }
    },
    methods: {
        redirectToHome(){
            window.location.replace(this.homeUrl);
        },
        copy() {
            this.$refs.clone.focus();
            document.execCommand('copy');
        },
        async onSubmitSignInForm(e){
            e.preventDefault();

            //Show Loader
            this.showLoadingAlert();

            const res = await fetch(`${this.baseURL}/api/v1/sign-in-participant`,{
                method: 'POST',
                headers:{
                'Accept':'application/json',
                'Content-type':'application/json'
                },
                body:JSON.stringify(this.signInForm)
            });

            const data = await res.json();

            if(data.success){
                //Bind token & id to local storage
                localStorage.setItem( 'token', JSON.stringify(data.data.access_token) );
                localStorage.setItem( 'user_id', JSON.stringify(data.data.id) );
                //Hide Spinner
                this.hideLoadingAlert();
                //Show Success alert
                this.showSuccessAlert('<p>Login successfuly</p>');
                //Clear Form
                this.signInForm.email = '';
                this.signInForm.password = '';

                this.token = JSON.parse( localStorage.getItem('token') );
                this.user_id = JSON.parse( localStorage.getItem('user_id') );

                //Hide reg form
                this.showLogInForm = false;

                //Load images
                this.loadImage();
            }else{
                //Hide Spinner
                this.hideLoadingAlert();
                //Show Error alert
                var errors = '<p>Sorry, incorrect logins!</p>';

                this.showErrorAlert(errors);
            }
        },
        async votePhoto(item){
            if(this.token === null || this.token === ''){
                //Hide reg form
                return false;
            }

            //Show Loader
            this.showLoadingAlert();

            const body = {
                'user_id':this.user_id,
                'photo_id':item.id
            }

            const res = await fetch(`${this.baseURL}/api/v1/votes`,{
                method: 'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-type':'application/json',
                    'Authorization':'Bearer ' + this.token
                },
                body:JSON.stringify(body)
            });

            const data = await res.json();

            if(data.success){
                //Hide Spinner
                this.hideLoadingAlert();
                //Down vote
                if((data.message === 'Voted for photo successfully!')){
                    item.attributes.votes += 1;
                    item.attributes.voted = true;
                    //Show Success alert
                    this.showSuccessAlert('<p>Voted successfuly</p>');
                }else if(data.message === 'Downvote for photo successful!'){
                    item.attributes.votes -= 1;
                    item.attributes.voted = false;
                    //Show Success alert
                    this.showSuccessAlert('<p>Downvote successfuly</p>');
                }
                
            }else{
                //Hide Spinner
                this.hideLoadingAlert();

                //Show Error alert
                var errors = '';

                data.errors.forEach((err) => {
                    errors = errors.concat(" ", `<li style="margin-bottom:0.5em;text-align: left;"><strong>${err.title}</strong> : ${err.details}</li>`);
                });

                this.showErrorAlert(errors);
            }

            // this.showErrorAlert('<p>Sorry, voting has not yet began!</p>');
        },
        showRegistrationForm(){
            this.showRegForm = true;
            this.showLogInForm = false;
        },
        showSignInForm(){
            this.showRegForm = false;
            this.showLogInForm = true;
        },
        clearForm(){
            this.form.full_names = '';
            this.form.email = '';
            this.form.phone_number = '';
            this.form.password = '';
            this.form.password_confirmation = '';
        },
        async loadImage(){

            //Loader
            this.showLoadingAlert();

            const res = await fetch(`${this.baseURL}/api/v1/user-photo`,{
                method: 'POST',
                headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + JSON.parse( localStorage.getItem('token') )
                }
            });

            const data = await res.json();

            if(data.success){
                //Set Gallery
                this.image = data.data;
                //Pagination
                //Loader
                this.hideLoadingAlert();
            }else{
                this.showErrorAlert('<p>Sorry, images could not be loaded</p>');
                //Loader
                this.hideLoadingAlert();
            }
        },
        showSuccessAlert(text) {
            // Use sweetalert2
            this.$swal({
                icon: "success",
                html: text,
                closeOnEsc: false,
                closeOnClickOutside: false,
                allowOutsideClick: false,
            });
        },
        showLoadingAlert(){
            this.loading = true;
        },
        hideLoadingAlert(){
            this.loading = false;
        },
        showErrorAlert(text) {
            // Use sweetalert2
            this.$swal({
                icon: 'error',
                title: 'Oops...errors occured!',
                html: text,
                allowOutsideClick: false,
            });
        },
    },
    created(){
        if(this.token !== null){
            this.loadImage();
        }
        //Loader
        // this.showLoadingAlert();
    }
}
</script>

<style lang="scss" scoped>
    @import "@fancyapps/ui/dist/fancybox.css";
    @import "https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;800&display=swap";
    @import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css";
    @import "../assets/scss/upload.scss";
    
    .gallery-page .inner .copy .img-wrapper .vote {
        padding-bottom: 1em;
    }

    .copy-to-clipboard{
        margin-top: 0.5em;
        width: 100%;
        display:flex;
        input{
            background: #fff;
            outline: none;
            border: none;
            text-indent: 5px;
            padding: 0.6em 0em;
            border-bottom-left-radius: 4px;
            border-top-left-radius: 4px;
            flex:1;
        }
        button{
            background: rgb(233, 233, 233);
            border: none;
            outline: none;
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
            color:rgb(252, 104, 104);
        }
    }

    .share-social{
        padding: 0px;
        list-style: none;
        display: flex;
        margin-top: 0.2em;
        a{
            width:35px !important;
            height: 35px;
            border:1px solid #fff;
            background: #fff;
            display:flex;
            justify-content: center;
            align-items: center;
            font-size:1.3em;
            text-decoration: none;
            border-radius:4px;
            margin-right: 1em;
        }
        a:active {
            background: #ccc;
        }
        a.whatsapp{
            color: rgb(18, 140, 126);
            border:1px solid rgb(18, 140, 126);
        }
        a.facebook{
            color: rgb(27, 83, 199);
            border:1px solid rgb(27, 83, 199);
        }
        a.twitter{
            color: rgb(0, 172, 238);
            border:1px solid rgb(0, 172, 238);
        }
        a.copy-link{
            color: rgb(252, 104, 104);
            border:1px solid rgb(252, 104, 104);
        }
    }

    @media only screen and (max-width: 800px){
        .gallery-page .inner .copy .img-wrapper{
            height: 320px;
        }
    }

    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-thumb {
        background: $yellow;
    }

    ::-webkit-scrollbar-track {
        background: #fff;
    }
</style>