import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PublicVoteView from '../views/PublicVoteView.vue'
import PublicVoteFarmingView from '../views/PublicVoteFarmingView.vue'
import PublicVoteWildlifeView from '../views/PublicVoteWildlifeView.vue'
import PublicVoteNomadicView from '../views/PublicVoteNomadicView.vue'
import PublicVoteLifestyleView from '../views/PublicVoteLifestyleView.vue'
import PublicVoteActivationAndEngagementView from '../views/PublicVoteActivationAndEngagementView.vue'
import PhotoJudgeVoteView from '../views/PhotoJudgeVoteView.vue'
import PhotoJudgeVoteKenyaView from '../views/PhotoJudgeVoteKenyaView.vue'
import PhotoJudgeVoteSouthAfricaView from '../views/PhotoJudgeVoteSouthAfricaView.vue'
import PhotoJudgeVoteTanzaniaView from '../views/PhotoJudgeVoteTanzaniaView.vue'
import PhotoJudgeVoteUgandaView from '../views/PhotoJudgeVoteUgandaView.vue'
import PhotoJudgeVoteEthiopiaView from '../views/PhotoJudgeVoteEthiopiaView.vue'
import PhotoJudgeVoteNamibiaView from '../views/PhotoJudgeVoteNamibiaView.vue'
import PhotoJudgeVoteMozambiqueView from '../views/PhotoJudgeVoteMozambiqueView.vue'
import PhotoJudgeVoteRwandaView from '../views/PhotoJudgeVoteRwandaView.vue'
import ParticipantDashboardView from '../views/ParticipantDashboardView.vue'
import IndividualPhotoVoteView from '../views/IndivualPhotoVoteView.vue'


const routes = [{
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/gallery',
        name: 'gallery',
        component: () =>
            import ('../views/GalleryView.vue')
    },
    {
        path: '/farming',
        name: 'farming',
        component: () =>
            import ('../views/FarmingView.vue')
    },
    {
        path: '/wildlife',
        name: 'wildlife',
        component: () =>
            import ('../views/WildlifeView.vue')
    },
    {
        path: '/nomadic-cultures',
        name: 'nomadic-cultures',
        component: () =>
            import ('../views/NomadicCulturesView.vue')
    },
    {
        path: '/lifestyle',
        name: 'lifestyle',
        component: () =>
            import ('../views/LifestyleView.vue')
    },
    {
        path: '/activation-and-engagement',
        name: 'activation-and-engagement',
        component: () =>
            import ('../views/ActivationAndEngagementView.vue')
    },
    {
        path: '/judge-vote',
        name: 'JudgesView',
        component: () =>
            import ('../views/JudgesVoteView.vue')
    },
    {
        path: '/judge-vote-kenya',
        name: 'JudgesKenyaView',
        component: () =>
            import ('../views/JudgesVoteKenyaView.vue')
    },
    {
        path: '/judge-vote-uganda',
        name: 'JudgesUgandaView',
        component: () =>
            import ('../views/JudgesVoteUgandaView.vue')
    },
    {
        path: '/judge-vote-tanzania',
        name: 'JudgesTanzaniaView',
        component: () =>
            import ('../views/JudgesVoteTanzaniaView.vue')
    },
    {
        path: '/judge-vote-south-africa',
        name: 'JudgesSouthAfricaView',
        component: () =>
            import ('../views/JudgesVoteSouthAfricaView.vue')
    },
    {
        path: '/judge-vote-ethiopia',
        name: 'JudgesEthiopiaView',
        component: () =>
            import ('../views/JudgesVoteEthiopiaView.vue')
    },
    {
        path: '/judge-vote-namibia',
        name: 'JudgesNamibiaView',
        component: () =>
            import ('../views/JudgesVoteNamibiaView.vue')
    },
    {
        path: '/judge-vote-mozambique',
        name: 'JudgesMozambiqueView',
        component: () =>
            import ('../views/JudgesVoteMozambiqueView.vue')
    },
    {
        path: '/judge-vote-rwanda',
        name: 'JudgesRwandaView',
        component: () =>
            import ('../views/JudgesVoteRwandaView.vue')
    },
    {
        path: '/view-public-vote',
        name: 'PublicVoteView',
        component: PublicVoteView
    },
    {
        path: '/view-public-vote-farming',
        name: 'PublicVoteFarmingView',
        component: PublicVoteFarmingView
    },
    {
        path: '/view-public-vote-wildlife',
        name: 'PublicVoteWildlifeView',
        component: PublicVoteWildlifeView
    },
    {
        path: '/view-public-vote-nomadic',
        name: 'PublicVoteNomadicView',
        component: PublicVoteNomadicView
    },
    {
        path: '/view-public-vote-lifestyle',
        name: 'PublicVoteLifestyleView',
        component: PublicVoteLifestyleView
    },
    {
        path: '/view-public-vote-activation-and-engagement',
        name: 'PublicVoteActivationAndEngagementView',
        component: PublicVoteActivationAndEngagementView
    },
    {
        path: '/view-judge-vote',
        name: 'PhotoJudgeVoteView',
        component: PhotoJudgeVoteView
    },
    {
        path: '/view-judge-vote-kenya',
        name: 'PhotoJudgeVoteKenyaView',
        component: PhotoJudgeVoteKenyaView
    },
    {
        path: '/view-judge-vote-south-africa',
        name: 'PhotoJudgeVoteSouthAfricaView',
        component: PhotoJudgeVoteSouthAfricaView
    },
    {
        path: '/view-judge-vote-tanzania',
        name: 'PhotoJudgeVoteTanzaniaView',
        component: PhotoJudgeVoteTanzaniaView
    },
    {
        path: '/view-judge-vote-uganda',
        name: 'PhotoJudgeVoteUgandaView',
        component: PhotoJudgeVoteUgandaView
    },
    {
        path: '/view-judge-vote-ethiopia',
        name: 'PhotoJudgeVoteEthiopiaView',
        component: PhotoJudgeVoteEthiopiaView
    },
    {
        path: '/view-judge-vote-namibia',
        name: 'PhotoJudgeVoteNamibiaView',
        component: PhotoJudgeVoteNamibiaView
    },
    {
        path: '/view-judge-vote-mozambique',
        name: 'PhotoJudgeVoteMozambiqueView',
        component: PhotoJudgeVoteMozambiqueView
    },
    {
        path: '/view-judge-vote-rwanda',
        name: 'PhotoJudgeVoteRwandaView',
        component: PhotoJudgeVoteRwandaView
    },
    {
        path: '/user',
        name: 'ParticipantDashboardView',
        component: ParticipantDashboardView
    },
    {
        path: '/photo',
        name: 'IndividualPhotoVoteView',
        component: IndividualPhotoVoteView
    },
    {
        path: '/about',
        name: 'about',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router