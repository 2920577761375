<template>
    <div class="gallery-page">
        <div id="loader" v-show="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
        </div>
        <div id="voter-registration-form" v-if="this.token === null || this.token === ''">
            <div class="overlay">
              <div class="inner-copy">
                <h2>
                    Sign In
                </h2>
                <form @submit="onSubmitSignInForm">
                  <input type="email" v-model="signInForm.email" name="email" id="loginemail" placeholder="Email*" class="input-type" required>
                  <input type="password" v-model="signInForm.password" name="password" id="loginpassword" placeholder="Enter password*" class="input-type" required>
                  <div class="input-group-btns">
                    <input type="submit" value="SIGN IN" class="input-submit small-btn yellow-btn">
                  </div>
                </form>
              </div>
            </div>
        </div>
        <div class="inner">
            <div class="top">
                <h4 class="large-title">
                    Judge Vote South Africa
                </h4>
                <a href="javascript:void(0);" class="small-btn yellow-btn" @click="redirectToHome">
                    <i class="fa-solid fa-arrow-left" style="margin-right: 0.5em;"></i> BACK
                </a>
            </div>
            <div class="navigation">
                <router-link to="/view-judge-vote">All</router-link> |
                <router-link to="/view-judge-vote-kenya">Kenya</router-link>
                <router-link to="/view-judge-vote-south-africa" class="active">South Africa</router-link> |
                <router-link to="/view-judge-vote-tanzania">Tanzania</router-link>
                <router-link to="/view-judge-vote-uganda">Uganda</router-link>
                <router-link to="/view-judge-vote-ethiopia">Ethiopia</router-link> |
                <router-link to="/view-judge-vote-namibia">Namibia</router-link> |
                <router-link to="/view-judge-vote-mozambique">Mozambique</router-link> |
                <router-link to="/view-judge-vote-rwanda">Rwanda</router-link>
            </div>
            <div class="copy">
                <div v-for="item in images" :key="item.id" class="img-wrapper">
                    <a href="javascript:void(0);" :style="{ backgroundImage: `url(${uploadsUrl + item.attributes.media_name})` }" data-fancybox="gallery" :data-src="uploadsUrl + item.attributes.media_name" :data-caption="item.attributes.caption">

                    </a>
                    <div class="vote">
                        <div class="left">
                            <h5 class="small-title" style="margin-bottom: 0em;">
                                Name: {{ item.attributes.user }}
                            </h5>
                            <p style="color: rgb(252,204,104);margin-bottom: 0.1em;">
                                Country: {{ item.attributes.country }}
                            </p>
                            <p>
                                Category: {{ item.attributes.category }}
                            </p>
                            <p>
                                Email: {{ item.attributes.email }}
                            </p>
                            <p>
                                Phone: {{ item.attributes.phone }}
                            </p>
                            <p>
                                Business Unit: {{ item.attributes.business_unit }}
                            </p>
                        </div>
                        <div class="right">
                            <h3>
                                Judge Vote(s): {{ item.attributes.judge_votes }}
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <VueEternalLoading :load="load"></VueEternalLoading>
            <div class="back-section">
                <a href="javascript:void(0);" @click="redirectToHome">
                    <i class="fa-solid fa-arrow-left"></i> BACK
                </a>
                <div class="img-wrapper">
                    <img src="../assets/imgs/logo-new.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//Fancybox
import { Fancybox } from "@fancyapps/ui";

//Lazy load
import lozad from 'lozad';

import { defineComponent, ref, getCurrentInstance } from 'vue';
import { VueEternalLoading, LoadAction } from '@ts-pro/vue-eternal-loading';

// const URL = 'https://safaleyeinthewild.tk/app/api/v1/photos';
// const URL = 'https://safaleyeinthewild.safalgroup.com/app/api/v1/photos-judge-votes';
// const URL = 'https://staff.safaleyeinthewild.safalgroup.com/app/api/v1/photos-judge-votes';
const URL = 'https://beanafrica.tk/app/api/v1/photos-judge-votes';
// const URL = 'http://eyeinthewild.test/api/v1/photos-judge-votes';
const PAGE_SIZE = 6;

export default {
    name: 'PhotoJudgeVoteWildlifeView',
    components: {
        Fancybox,
        VueEternalLoading
    },
    data() {
        return {
            // homeUrl:'https://safaleyeinthewild.tk/',
            // baseURL:'https://safaleyeinthewild.tk/app',

            // localBaseURL: 'http://eyeinthewild.test',
            // homeUrl:'http://localhost:8080/#/',
            // baseURL:'http://eyeinthewild.test',
            // uploadsUrl:'http://eyeinthewild.test/storage/photos/2022/',

            // homeUrl:'https://safaleyeinthewild.safalgroup.com/uat/#/',
            // baseURL:'https://safaleyeinthewild.safalgroup.com/app',            
            // uploadsUrl:'https://safaleyeinthewild.safalgroup.com/competitionapp/storage/app/public/photos/2023/',

            //Staff
            homeUrl:'https://staff.safaleyeinthewild.safalgroup.com/#/',
            // baseURL:'https://staff.safaleyeinthewild.safalgroup.com/app',            
            // uploadsUrl:'https://staff.safaleyeinthewild.safalgroup.com/competitionapp/storage/app/public/photos/2023/',
            baseURL:'https://beanafrica.tk/app',          
            uploadsUrl:'https://beanafrica.tk/competitionapp/storage/app/public/photos/2023/',

            loading: false,
            showRegForm: false,
            showLogInForm: false,
            gallery:[],
            currentPage: 1,
            pageCount: 0,
            token:JSON.parse( localStorage.getItem('token') ),
            user_id:JSON.parse( localStorage.getItem('user_id') ),
            form:{
                full_names:"",
                email:"",
                phone_number:"",
                password:"",
                password_confirmation:"",
            },
            signInForm: {
                email:"",
                password:"",
            }
        }
    },
    methods: {
        redirectToHome(){
            window.location.replace(this.homeUrl);
        },
        async onSubmitSignInForm(e){
            e.preventDefault();

            //Show Loader
            this.showLoadingAlert();

            const res = await fetch(`${this.baseURL}/api/v1/sign-in-judge`,{
                method: 'POST',
                headers:{
                'Accept':'application/json',
                'Content-type':'application/json'
                },
                body:JSON.stringify(this.signInForm)
            });

            const data = await res.json();

            if(data.success){
                //Bind token & id to local storage
                localStorage.setItem( 'token', JSON.stringify(data.data.access_token) );
                localStorage.setItem( 'user_id', JSON.stringify(data.data.id) );
                //Hide Spinner
                this.hideLoadingAlert();
                //Show Success alert
                this.showSuccessAlert('<p>Login successfuly</p>');
                //Clear Form
                this.signInForm.email = '';
                this.signInForm.password = '';

                this.token = JSON.parse( localStorage.getItem('token') );
                this.user_id = JSON.parse( localStorage.getItem('user_id') );

                //Load images
                window.location.reload();

                //Hide reg form
                this.showLogInForm = false;
            }else{
                //Hide Spinner
                this.hideLoadingAlert();
                //Show Error alert
                var errors = '<p>Sorry, incorrect logins!</p>';

                this.showErrorAlert(errors);
            }
        },
        showRegistrationForm(){
            this.showRegForm = true;
            this.showLogInForm = false;
        },
        showSignInForm(){
            this.showRegForm = false;
            this.showLogInForm = true;
        },
        clearForm(){
            this.form.full_names = '';
            this.form.email = '';
            this.form.phone_number = '';
            this.form.password = '';
            this.form.password_confirmation = '';
        },
        async loadImages(){

            //Loader
            this.showLoadingAlert();

            const res = await fetch(`${this.baseURL}/api/v1/photos-judge-votes`,{
                method: 'GET',
                headers:{
                'Accept':'application/json',
                }
            });

            const data = await res.json();

            if(data.success){
                //Set Gallery
                this.gallery = data.data;
                //Pagination
                //Loader
                this.hideLoadingAlert();
            }else{
                this.showErrorAlert('<p>Sorry, images could not be loaded</p>');
                //Loader
                this.hideLoadingAlert();
            }
        },
        showSuccessAlert(text) {
            // Use sweetalert2
            this.$swal({
                icon: "success",
                html: text,
                closeOnEsc: false,
                closeOnClickOutside: false,
                allowOutsideClick: false,
            });
        },
        showLoadingAlert(){
            this.loading = true;
        },
        hideLoadingAlert(){
            this.loading = false;
        },
        showErrorAlert(text) {
            // Use sweetalert2
            this.$swal({
                icon: 'error',
                title: 'Oops...errors occured!',
                html: text,
                allowOutsideClick: false,
            });
        },
    },
    setup() {
        const images = ref([]);
        let page = 1;
        // var loader = true;

        function loadImages(page) {
            //Loader
            // alert(loading);
            return fetch(`${URL}?delay=1&per_page=${PAGE_SIZE}&page=${page}&country=South Africa`,{
                method: 'GET',
                headers:{
                'Accept':'application/json',
                'Authorization':'Bearer ' + JSON.parse( localStorage.getItem('token') )
                }
            }).then((res) => res.json())
                .then((res) => res.data);
        }

        async function load({ loaded }) {
            const loadedImages = await loadImages(page);
            images.value.push(...loadedImages);
            page += 1;
            loaded(loadedImages.length, PAGE_SIZE);
            // loader = false;
        }

        return {
            load,
            images,
            // loader:loader
        };
    },
    mounted(){
        // console.log(this.token);
        // this.loadImages();
        //Loader
        // this.showLoadingAlert();
    }
}
</script>

<style lang="scss" scoped>
    @import "@fancyapps/ui/dist/fancybox.css";
    @import "https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;800&display=swap";
    @import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css";
    @import "../assets/scss/upload.scss";

    .gallery-page .inner .copy .img-wrapper{
        display: flex;
        flex-direction: column;
        height: auto;
    }

    .gallery-page .inner .copy .img-wrapper a {
        height: 200px !important;
        flex: unset !important;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .gallery-page .inner .copy .img-wrapper .vote{
        position: relative !important;
        bottom: unset;
        background: rgb(0, 0, 0) !important;
    }

    .gallery-page .inner .copy .img-wrapper .vote .right h3{
        cursor: auto !important;
    }

    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-thumb {
        background: $yellow;
    }

    ::-webkit-scrollbar-track {
        background: #fff;
    }
</style>